<template lang="pug">
    div(class="sz-MotionSummaryPanel")
        div(
            v-if="moduleHasRiskTypes"
            class="sz-MotionSummaryPanel-summaryFigure")
            CharacterViewer(
                :quatKeys="quatKeys",
                :jointKeys="jointKeys",
                :loadingGraph="false",
                :showJointColoursOnly="true",
                class="sz-MotionSummaryPanel-character")
        div(
            v-else,
            class="sz-MotionSummary-empty")
</template>

<script>
    import SummaryFigure from './SummaryFigure'
    import RiskArea from './RiskArea'
    import EventBus from 'src/eventBus'
    import CharacterViewer from 'components/Assessment/CharacterViewer'
    import constants from 'helpers/constants'
    import { mapGetters } from 'vuex'
    import { mapJointsToColors } from 'helpers/graph'

    export default {
        name: "ErgoSummary",

        components: {
            SummaryFigure,
            RiskArea,
            CharacterViewer,
        },

        computed: {
            ...mapGetters([
                'currentCaptureByModule',
                'selectedAssessment',
            ]),

            moduleHasRiskTypes () {
                let currentCapture = this.currentCaptureByModule(constants.RISK_MODULES.MOTION)
                return currentCapture
                    && this.selectedAssessment.motion.riskTypes
                    // && currentCapture.hasSKU Need to add when backend is ready
            },

            currentCaptureRiskScores () {
                return this.currentCaptureByModule(constants.RISK_MODULES.MOTION).RiskTypes
            },

            jointKeys () {
                return constants.DEFAULT_ANIMATOR_JOINT_KEYS
            },

            quatKeys () {
                return constants.DEFAULT_ANIMATOR_QUAT_KEYS
            },


        },

        mounted () {
            this.getInitialJointColors()
        },

        methods: {
            getInitialJointColors () {
                const riskTypes = this.selectedAssessment.motion.riskTypes
                const jointRisks = Object.keys(riskTypes).reduce(function (acc, riskType) {
                    acc[riskType] = riskTypes[riskType].risk
                    return acc
                }, {})

                EventBus.$emit('UPDATE_CHARACTER_VIEWER', mapJointsToColors(jointRisks))
            },
        },
    }
</script>

<style lang="scss" scoped>
    .sz-MotionSummaryPanel {
        height: 100%;

        &-summaryFigure {
            display: flex;
            flex-direction: row;
            height: 100%;
            justify-content: center;
            margin-top: 1rem;
        }

        &-character {
            width: 100%;
        }
    }
</style>

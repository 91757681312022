import colors from 'design/_colors.scss'
import constants from 'helpers/constants'

export function decomposeRROY(rroy){
  let Rr = Math.floor(rroy*10)/10
  let o = Math.floor((rroy - Rr)*100)/100
  let y = Math.round((rroy - Rr - o)*1000)/1000

  return [Rr, o, y]
}

export function getJointRiskColor(rroy) {
  if (rroy[0] > 0 && (rroy[0] >= rroy[1] || rroy[0] >= rroy[2])) return 3
  if (rroy[1] > 0 && rroy[1] >= rroy[2]) return 2
  if (rroy[2] > 0) return 1
  return 0
}

export function mapJointsToColors(pointData) {
  let jointArray = new Array(7).fill(0)
  Object.keys(constants.DEFAULT_ANIMATOR_JOINT_KEYS).forEach(key => {
    if (pointData[key] !== undefined) {
      jointArray[constants.DEFAULT_ANIMATOR_JOINT_KEYS[key]] = getJointRiskColor(decomposeRROY(pointData[key]))
    }
  })
  return jointArray
}

export function genRiskGraph(state) {
  let riskGraph = {labels: [], datasets: []}
  let red = []
  let orange = []
  let yellow = []

  for (let capture of state.assessments) {
    let currentModuleRisk = capture.ModuleSummaries
        .find(module => module.name.toLowerCase() === state.selectedModule)
        .risk
    let heights = decomposeRROY(currentModuleRisk)

    red.push(heights[0])
    orange.push(heights[1])
    yellow.push(heights[2])
  }

  riskGraph.labels = red.map((m) => '')

  riskGraph.datasets.push({label: "", backgroundColor: colors['color-risk-yellow'], data: yellow})
  riskGraph.datasets.push({label: "", backgroundColor: colors['color-risk-orange'], data: orange})
  riskGraph.datasets.push({label: "", backgroundColor: colors['color-risk-red'], data: red})
  return riskGraph
}

export function genSpatialGraph(state) {
  let riskGraph = {labels: [], datasets: []}
  let poseData = {}

  for (let capture of state.captures) {
    let spatialRiskTypes = capture.ModuleSummaries
        .find(module => module.name.toLowerCase() === constants.RISK_MODULES.SPATIAL)
        .RiskTypes

    let spatialStf = spatialRiskTypes
        ? spatialRiskTypes
            .find(riskType => riskType.name === constants.SPATIAL_RISK_TYPES.STF)
            .payload
        : false

    for (let poseType in constants.POSE_TYPES) {
      if(constants.POSE_TYPES[poseType] != constants.POSE_TYPES.IN_VEHICLE) { // we do not graph time spent in vehicle atm
        if(!poseData[constants.POSE_TYPES[poseType]]) {
          poseData[constants.POSE_TYPES[poseType]] = []
        }
        if (spatialStf) {
          poseData[constants.POSE_TYPES[poseType]].push(spatialStf[constants.POSE_TYPES[poseType]])
        }
      }
    }
  }

  for (let pose in poseData) {
    riskGraph.datasets.push({label: "", backgroundColor: colors["color-pose-" + pose], data: poseData[pose]})
  }

  riskGraph.labels = riskGraph.datasets[0].data.map((m) => "")
  return riskGraph
}

<template lang="pug">
    div(class="PostureDistributionChart")
</template>

<script>
    import Anychart from 'anychart'
    import EventBus from 'src/eventBus'
    import constants from 'helpers/constants'
    import cloneDeep from 'lodash'
    import { mapGetters } from 'vuex'
    import { postureStyling } from 'helpers/summaryGraph'
    import { getLine } from 'helpers/individualSummaryGraph'

    export default {
        name: 'PostureDistributionChart',

        data() {
            return {
                graphData: null,
                chart: null,
                mappings: {},
                series: {},
            }
        },

        computed: {
            ...mapGetters([
                'selectedAssessment',
                'disabledRiskTypes',
            ]),
        },

        watch: {
            selectedAssessment: function() {
                if (this.chart) {
                  this.chart.dispose()
                  this.chart = null
                }
                this.init()
            },

            disabledRiskTypes: function () {
                this.toggleEnabledRiskTypes()
            },
        },

        mounted() {
            if (!this.chart) {
                this.init()
            }
        },

        updated() {
            this.init()
        },

        beforeDestroy() {
            if (this.chart) {
                this.chart.dispose()
                this.chart = null
            }
        },

        methods: {
            init() {
              if (!this.chart) {
                  Anychart.licenseKey(process.env.VUE_APP_ANYCHART_LICENSE)
                  this.chart = Anychart.column()
                  this.addLicenseKey()
                  this.getGraphData()
                  this.createMapping()
                  this.createSeries()
                  this.toggleEnabledRiskTypes()
                  this.addGeneralSettings()
                  this.chart.container(this.$el)
                  this.chart.draw()
              }
            },

            addLicenseKey() {
                let credits = this.chart.credits()
                credits.enabled(false)
            },

            getGraphData() {
                this.graphData = Object.keys(this.selectedAssessment.posture.riskTypes).reduce((result, riskType) => {
                    let payload = this.selectedAssessment.posture.riskTypes[riskType].payload

                    for (let i = payload.histogramBinStart; i <= payload.histogramBinEnd; i += payload.histogramBinWidth) {
                      let existing = result.find(x => x.angle === i)
                      if (existing) {
                        existing[`${riskType}Name`] = this.$t(`riskTypes.${riskType}`)
                        existing[riskType] = payload.histogramBinValues[(i/payload.histogramBinWidth - payload.histogramBinStart/payload.histogramBinWidth)] || 0
                      } else {
                        let item = {
                          angle: i,
                        }
                        item[`${riskType}Name`] = this.$t(`riskTypes.${riskType}`)
                        item[riskType] = payload.histogramBinValues[(i/payload.histogramBinWidth - payload.histogramBinStart/payload.histogramBinWidth)] || 0,
                        result.push(item)
                      }
                    }

                    return result
                }, [])
            },

            createMapping() {
                let dataSet = Anychart.data.set(this.graphData)
                Object.keys(this.selectedAssessment.posture.riskTypes).map(riskType => {
                    this.mappings[riskType] = dataSet.mapAs({ x: `angle`, value: `${riskType}`, name: `${riskType}Name` })
                })
            },

            createSeries() {
                Object.keys(this.selectedAssessment.posture.riskTypes).map(riskType => {
                    this.series[riskType] = this.chart.column(this.mappings[riskType])
                    this.series[riskType].stroke(getLine(constants.RISK_MODULES.POSTURE,riskType))
                    this.series[riskType].fill(getLine(constants.RISK_MODULES.POSTURE,riskType))
                })
            },

            addGeneralSettings() {
                postureStyling(this.chart)
                this.chart.yAxis().title(this.$t(`individualSummary.postureSummary.yAxisLabel`).toUpperCase())
            },

            toggleEnabledRiskTypes() {
                Object.keys(this.selectedAssessment.posture.riskTypes).map(riskType => {
                    const isEnabled = !this.disabledRiskTypes.includes(riskType)
                    this.series[riskType].enabled(isEnabled)
                })
            },
        },
    }
</script>

<style lang="scss">
@import '~design';
.PostureDistributionChart {
    height: 100%;
    width: 100%;
    padding-top: 1rem;
    padding-right: 1.5rem;

    @include anychart-tooltip;
    .anychart-tooltip {
        width: 6.25rem;
        margin-left: 1rem;
    }
}

</style>

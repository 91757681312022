import constants from 'helpers/constants'
import { decomposeRROY } from 'helpers/graph'
import colors from "design/_colors.scss"

export const generalSettings = {
    chart: {
        width: '100%',
        height: '100%',
        tooltip: {
            enabled: true,
            html: true,
            separator: false,
            anchor: 'left-bottom',
            titleFormat: '{%x}{dateTimeFormat:MMM dd y}',
            offsetY: '-80',
            pointMode: 'point',
        },
        background: {
            fill: `#262626`,
        },
        dataArea: {
            fill: `#454545`,
        },
        yAxis: {
            axisColor: constants.COLORS.WIDGET.AXIS_COLOR,
            labels: {
                enabled: true,
                fontFamily:'Source Sans Pro',
                fontColor: constants.COLORS.WIDGET.YAXIS_COLOR,
                fontSize: '10px',
                fontWeight: '600',
            },
            title: {
                fontSize: '11px',
                fontWeight: '600',
                fontFamily: 'Source Sans Pro',
                fontColor: constants.COLORS.WIDGET.TITLE_LABEL_COLOR,
            },
            ticks: {
                enabled: false,
            },
        },

        xAxis: {
            axisColor: constants.COLORS.WIDGET.AXIS_COLOR,
            labels: {
                enabled: true,
                fontSize: '12.5px',
                fontFamily:'Source Sans Pro',
                fontColor: constants.COLORS.WIDGET.XAXIS_COLOR,
                textOverflow: '...',
                wrap: 'break-all',
                hAlign: 'end',
                format: `{%tickValue}{dateTimeFormat:MMM dd yyyy}`,
                caps: 'small-caps',
            },
            ticks: {
                enabled: false,
            },
        },
        grid: {
            enabled: true,
            color: constants.COLORS.WIDGET.GRID_COLOR,
        },
        listen: {
            type: 'hover',
        },
        interactivity: {
            none: 'none',
        },
        dateMarker: {
            thickness: 300,
            opacity: 0.2,
        },
        markers: {
            enabled: true,
            size: 5,
            colors: {
                red: colors['color-risk-red'],
                orange: colors['color-risk-orange'],
                yellow: colors['color-risk-yellow'],
                green: colors['color-risk-green'],
              },
        },
    },
}

export function setMarkerColor(risk) {
    var color
    if (risk >= 0.1) {
        color = generalSettings.chart.markers.colors.red
    }
    else if (risk < 0.1 && risk >= 0.01) {
        color = generalSettings.chart.markers.colors.orange
    }
    else if (risk < 0.01 && risk >= 0.001) {
        color = generalSettings.chart.markers.colors.yellow
    }
    else {
        color = generalSettings.chart.markers.colors.green
    }
    return {
        enabled: generalSettings.chart.markers.enabled,
        fill: color,
        stroke: color,
        size: generalSettings.chart.markers.size,
    }
}

export function replaceLabels() {
    if (this.value === 0.0032) {
        return "Low"
    }
    else if (this.value === 0.032) {
        return "Med"
    }
    else if (this.value === 0.320) {
        return "High"
    }
    else {
        return ""
    }
}

export function replaceLinearLabels() {
    if (this.value === 0.05) {
        return "Med"
    }
    else if (this.value === 0.550) {
        return "High"
    }
    else {
        return
    }
}

export function postureStyling (chart) {
    chart.background().fill(generalSettings.chart.background.fill)
    chart.dataArea().background().enabled(true)
    chart.dataArea().background().fill(generalSettings.chart.dataArea.fill)

    var xAxis = chart.xAxis()
    xAxis.ticks().enabled(generalSettings.chart.xAxis.ticks.enabled)
    xAxis.stroke(generalSettings.chart.xAxis.axisColor)

    var xAxisLabels = xAxis.labels()
    xAxisLabels.enabled(generalSettings.chart.xAxis.labels.enabled)
    xAxisLabels.fontSize(generalSettings.chart.xAxis.labels.fontSize)
    xAxisLabels.minFontSize(generalSettings.chart.xAxis.labels.fontSize)
    xAxisLabels.fontFamily(generalSettings.chart.xAxis.labels.fontFamily)
    xAxisLabels.fontColor(generalSettings.chart.xAxis.labels.fontColor)
    xAxisLabels.textOverflow(generalSettings.chart.xAxis.labels.textOverflow)
    xAxisLabels.wordWrap(generalSettings.chart.xAxis.labels.wrap)
    xAxisLabels.hAlign(generalSettings.chart.xAxis.labels.hAlign)
    xAxisLabels.fontVariant(generalSettings.chart.xAxis.labels.caps)

    var yAxis = chart.yAxis()
    yAxis.ticks().enabled(generalSettings.chart.yAxis.ticks.enabled)
    yAxis.stroke(generalSettings.chart.yAxis.axisColor)

    var yAxisLabels = yAxis.labels()
    yAxisLabels.fontSize(generalSettings.chart.yAxis.labels.fontSize)
    yAxisLabels.fontColor(generalSettings.chart.yAxis.labels.fontColor)
    yAxisLabels.fontWeight(generalSettings.chart.yAxis.labels.fontWeight)
    yAxisLabels.fontFamily(generalSettings.chart.yAxis.labels.fontFamily)

    var title = yAxis.title()
    title.fontSize("10px")
    title.fontWeight("600")
    title.fontFamily("Source Sans Pro")
    title.fontColor("rgba(255, 255, 255, 0.75)")

    var tooltip = chart.tooltip()
    tooltip.useHtml(generalSettings.chart.tooltip.enabled)
    tooltip.separator(generalSettings.chart.tooltip.separator)
    tooltip.displayMode('single')
    tooltip.anchor(generalSettings.chart.tooltip.anchor)
    tooltip.positionMode(generalSettings.chart.tooltip.pointMode)
    tooltip.offsetY(generalSettings.chart.tooltip.offsetY)
    tooltip.titleFormat("Angle: {%x}°")
    tooltip.format(function() {
        var duration = this.getData('value')
        var name = this.getData('name')
        return `<div class="label">${name}<br></div><div class="anychart-tooltip-riskScore">${duration}<div>`
    })

    chart.interactivity().selectionMode('none')
}

export function generalStyling (chart, aggregateChart = false) {
    chart.background().fill(generalSettings.chart.background.fill)
    chart.dataArea().background().enabled(true)
    chart.dataArea().background().fill(generalSettings.chart.dataArea.fill)

    // labels
    var xAxisLabels = chart.xAxis().labels()
    xAxisLabels.enabled(generalSettings.chart.xAxis.labels.enabled)
    xAxisLabels.fontSize(generalSettings.chart.xAxis.labels.fontSize)
    xAxisLabels.minFontSize(generalSettings.chart.xAxis.labels.fontSize)
    xAxisLabels.fontFamily(generalSettings.chart.xAxis.labels.fontFamily)
    xAxisLabels.fontColor(generalSettings.chart.xAxis.labels.fontColor)
    xAxisLabels.textOverflow(generalSettings.chart.xAxis.labels.textOverflow)
    xAxisLabels.wordWrap(generalSettings.chart.xAxis.labels.wrap)
    xAxisLabels.hAlign(generalSettings.chart.xAxis.labels.hAlign)
    xAxisLabels.format(generalSettings.chart.xAxis.labels.format)
    xAxisLabels.fontVariant(generalSettings.chart.xAxis.labels.caps)

    var yAxisLabels = chart.yAxis().labels()
    yAxisLabels.fontSize(generalSettings.chart.yAxis.labels.fontSize)
    yAxisLabels.fontColor(generalSettings.chart.yAxis.labels.fontColor)
    yAxisLabels.fontWeight(generalSettings.chart.yAxis.labels.fontWeight)
    yAxisLabels.fontFamily(generalSettings.chart.yAxis.labels.fontFamily)
    var title = chart.yAxis().title()
    title.fontSize("10px")
    title.fontWeight("600")
    title.fontFamily("Source Sans Pro")
    title.fontColor("rgba(255, 255, 255, 0.75)")

    // grid lines and ticks
    chart.yAxis().ticks().enabled(generalSettings.chart.yAxis.ticks.enabled)
    chart.yAxis().stroke(generalSettings.chart.yAxis.axisColor)
    chart.xAxis().ticks().enabled(generalSettings.chart.xAxis.ticks.enabled)
    chart.xAxis().stroke(generalSettings.chart.xAxis.axisColor)

    // tooltip
    var tooltip = chart.tooltip()
    tooltip.useHtml(generalSettings.chart.tooltip.enabled)
    tooltip.separator(generalSettings.chart.tooltip.separator)
    chart.interactivity("by-x")
    chart.interactivity().selectionMode("none")
    tooltip.displayMode('single')
    tooltip.anchor(generalSettings.chart.tooltip.anchor)
    tooltip.positionMode(generalSettings.chart.tooltip.pointMode)
    tooltip.offsetY(generalSettings.chart.tooltip.offsetY)

    tooltip.titleFormat(generalSettings.chart.tooltip.titleFormat)

    tooltip.format(function() {
        var riskNum = this.getData('value')
        if (!aggregateChart) {
            var color = this.getData('marker').fill
            if (color === generalSettings.chart.markers.colors.green) {
              riskNum = '000'
            } else {
              riskNum = riskNum.toFixed(3).toString().replace('0.', '')
            }
        }
        var name = this.seriesName
        return `<div class="label">${name}<br></div><div class="anychart-tooltip-riskScore">${riskNum}<div>`
    })
}

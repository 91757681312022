<template lang="pug">
    table(class="sz-ExposureDurationTable")
        div(class="sz-ExposureDurationTable-title") {{ $t(`individualSummary.thermalSummary.duration`)}}
        div(class="sz-ExposureDurationTable-headers")
            div(
                class="sz-ExposureDurationTable-header",
                v-for="risk in riskTypes") {{ risk.label }}
        div(class="sz-ExposureDurationTable-row")
            div(
                v-for="risk in riskTypes",
                class="sz-ExposureDurationTable-cell",
                :class="`sz-ExposureDurationTable-row-color-` + risk.label") {{ risk.value }}
</template>

<script>
    import { mapGetters } from 'vuex'
    import constants from 'helpers/constants'

    export default {

        computed: {
            ...mapGetters ([
                'currentCaptureByModule',
                'selectedAssessment',
            ]),

            captureData () {
                return this.selectedAssessment.thermal
            },

            captureTimeSpent () {
                return this.captureData.timeSpent || {}
            },

            riskTypes () {
                return Object.values(constants.THERMAL_RISK_SCORES)
                    .map((riskType) => {
                        return {
                            label: this.$t(`individualSummary.thermalSummary.durationTypes.${riskType}`),
                            type: riskType,
                            value: this.convertToMinutes(this.captureTimeSpent[riskType]) || '-',
                        }
                    })
            },
        },

        methods: {
            convertToMinutes(time) {
                if (!time) return undefined
                return (time / constants.SECONDS_IN_MINUTE).toFixed(1)
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    table {
        width: 100%;
        table-layout: fixed;
        border-collapse: collapse;
        
    }

    .sz-ExposureDurationTable {
        &-title {
            display: table-caption;
            background-color: $color-black;
            @extend %font-heading;
            font-size: 0.75rem;
            padding: 0.75rem 0.5rem;
            text-align: left;
            border-top: 0.15rem solid $color-body-bg;
            border-left: 0.15rem solid $color-body-bg;
            border-right: 0.15rem solid $color-body-bg;
        }

        &-headers {
            display: table-header-group;
        }

        &-header {
            display: table-cell;
            @extend %font-heading;
            font-size: 0.75rem;
            padding: 0.5rem 0.5rem;
            background-color: $color-table-dark-background;
            border: 0.15rem solid $color-body-bg;
        }

        &-row {
            display: table-row;
        }

        &-cell {
            display: table-cell;
            padding: 0.5rem 0.5rem;
            border: 0.15rem solid $color-body-bg;
            color: $color-body-bg;
        }

        &-row-color {
            &-Green {
                background-color: $color-risk-green;
            }
            &-Yellow {
                background-color: $color-risk-yellow;
            }
            &-Orange {
                background-color: $color-risk-orange;
            }
            &-Red {
                background-color: $color-risk-red;
            }
        }
    }
</style>
<template lang="pug">
    div(class="sz-ThermalSummaryPanel")
        BodyAmbientTable
        ExposureDurationTable
</template>

<script>
    import BodyAmbientTable from './BodyAmbientTable'
    import ExposureDurationTable from './ExposureDurationTable'

    export default {
        name: "ThermalSummary",

        components: {
            BodyAmbientTable,
            ExposureDurationTable,
        },
    }
</script>

<style lang="scss" scoped>
    .sz-ThermalSummaryPanel {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }

</style>

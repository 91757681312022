<template lang="pug">
    table(class="sz-BodyAmbientTable")
        div(class="sz-BodyAmbientTable-headers")
            div(class="sz-BodyAmbientTable-header") {{ $t(`individualSummary.thermalSummary.bodyAmbient`) }}
            div(class="sz-BodyAmbientTable-header") {{ $t(`individualSummary.thermalSummary.celsius`) }}
            div(class="sz-BodyAmbientTable-header") {{ $t(`individualSummary.thermalSummary.fahrenheit`) }}
        div(
            v-for="row of temperatures",
            class="sz-BodyAmbientTable-row")
            div(class="sz-BodyAmbientTable-label") {{ row.label }}
            div(class="sz-BodyAmbientTable-cell") {{ row.celsiusValue }}
            div(class="sz-BodyAmbientTable-cell") {{ row.fahrenheitValue }}
</template>

<script>
    import { mapGetters } from 'vuex'
    import constants from 'helpers/constants'
    import { celsiusToFahrenheit } from 'helpers/utilities'

    export default {

        computed: {
            ...mapGetters([
                'currentCaptureByModule',
                'selectedAssessment',
            ]),

            captureData () {
                return this.selectedAssessment.thermal
            },

            maxBodyAmbient () {
                if (!this.captureData.maxBodyAmbient) return {}

                return {
                    celsius: this.captureData.maxBodyAmbient.toFixed(1),
                    fahrenheit: celsiusToFahrenheit(this.captureData.maxBodyAmbient).toFixed(1),
                }
            },

            minBodyAmbient () {
                if (!this.captureData.minBodyAmbient) return {}

                return {
                    celsius: this.captureData.minBodyAmbient.toFixed(1),
                    fahrenheit: celsiusToFahrenheit(this.captureData.minBodyAmbient).toFixed(1),
                }
            },

            temperatures () {
                return [
                    {
                        label: this.$t(`individualSummary.thermalSummary.min`),
                        celsiusValue: this.minBodyAmbient.celsius || '-',
                        fahrenheitValue: this.maxBodyAmbient.fahrenheit || '-',
                    },
                    {
                        label: this.$t(`individualSummary.thermalSummary.max`),
                        celsiusValue: this.maxBodyAmbient.celsius || '-',
                        fahrenheitValue: this.minBodyAmbient.fahrenheit || '-',
                    },
                ]
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    th {
        display: table-caption;
    }

    table {
        width: 100%;
        table-layout: fixed;
        border-collapse: collapse;
    }

    .sz-BodyAmbientTable {
        margin: 0.5rem 0;

        &-headers {
            background-color: $color-black;
            display: table-header-group;
            margin-bottom: 0.3rem;
        }

        &-header {
            @extend %font-heading;
            padding: 0.75rem 0.5rem;
            font-size: 0.75rem;
            display: table-cell;
            text-align: center;
            vertical-align: middle;
        }

        &-row {
            display: table-row-group;
            padding: 0.5rem;
            background-color: $color-table-dark-background;
        }

        &-cell {
            border: 0.15rem solid $color-body-bg;
            padding: 0.5rem;
            display: table-cell;

        }

        &-label {
            @extend %font-heading;
            font-size: 0.75rem;
            display: table-cell;
            border: 0.15rem solid $color-body-bg;
        }
    }
</style>

<template lang="pug">
    div(class="sz-CaptureSummaryPanel")
        component(:is="currentModule")
</template>

<script>
    import RiskArea from './Motion/RiskArea'
    import ErgoSummary from './Motion/ErgoSummary'
    import ThermalSummary from './Thermal/ThermalSummary'

    import { mapGetters } from 'vuex'
    import constants from 'helpers/constants'

    export default {
        name: "CaptureSummaryPanel",

        components: {
            RiskArea,
            ErgoSummary,
            ThermalSummary,
        },

        computed: {
            ...mapGetters([
                'selectedModule',
            ]),

            currentModule () {
                return constants.RISK_TYPE_RISK_SUMMARY_COMPONENTS_MAP[this.selectedModule]
            },
        },
    }
</script>

<style lang="scss" scoped>
    .sz-CaptureSummaryPanel {
        width: 100%;
    }
</style>

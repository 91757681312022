<template lang="pug">
    div(class="sz-SummaryFigure")
        img(
            v-bind:src="image",
            class="sz-SummaryFigure-image")

</template>

<script>
    export default {
        name: "SummaryFigure",

        data () {
            return {
                image: require("../../../assets/images/Figurine_Transparent.png"),
            }
        },
    }
</script>

<style lang="scss" scoped>
    .sz-SummaryFigure {
        display: flex;

        &-image {
            height: 25vh;
        }
    }

</style>

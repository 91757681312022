<template lang="pug">
div(class="ChartAssessmentsSummary")
    FormSectionToggle(v-if="!isAggregate",
                    :defaultChecked="false",
                    :options="toggleOptions",
                    :title="this.$t(`individualSummary.riskSeverity`)",
                    :showTitle="false",
                    @changed="setGraphToggle"
                    class="ChartAssessmentsSummary-toggle"
                    ref="toggleGraph")
</template>

<script>
    import Anychart from 'anychart'
    import { generalSettings, generalStyling, replaceLabels, replaceLinearLabels, setMarkerColor } from 'helpers/summaryGraph'
    import { mapGetters } from 'vuex'
    import constants from 'helpers/constants'
    import { cloneDeep } from 'lodash'
    import { getLine } from 'helpers/individualSummaryGraph'
    import EventBus from 'src/eventBus'
    import { mapJointsToColors } from 'helpers/graph'
    import LoadingSpinner from 'components/Shared/LoadingSpinner'
    import motionAssessmentUtils from 'mixins/motionAssessment'
    import FormSectionToggle from 'components/Shared/FormSectionToggle'


    export default {
        name: 'ChartAssessmentsSummary',

         components: {
            FormSectionToggle,
        },

        mixins: [
            motionAssessmentUtils,
        ],

        data() {
            return {
                chart: null,
                series: {},
                mappings: {},
                previousModule: '',
                toggleGraph: false,
            }
        },

        computed: {
            ...mapGetters([
                'assessments',
                'selectedAssessmentTimestamp',
                'selectedAssessment',
                'selectedModule',
                'disabledRiskTypes',
            ]),

            toggleOptions () {
                return ['', this.$t(`enhanceRedSeverityLabels.toggleGraph`)]
            },

            isAggregate() {
                return this.selectedModule === constants.RISK_MODULES.AGGREGATE
            },

            graphData () {
                return Object.values(this.assessments)
                    // Assessments are initially received as an object - their order is not guaranteed and must be sorted
                    .sort(function(a, b) { return a.timestamp - b.timestamp})
                    .map((assessment) => {
                        let that = this
                        const risks = Object.values(constants.RISK_MODULES).reduce(function(acc, module) {
                            let riskTypes
                            if (assessment[module] !== undefined) {
                                riskTypes = module === constants.RISK_MODULES.AGGREGATE
                                    ? {
                                        [constants.RISK_MODULES.AGGREGATE]: assessment[module].risk,
                                    }
                                    : that.extractRiskValues(assessment[module].riskTypes)
                            }

                            acc = {
                                ...acc,
                                ...riskTypes,
                            }
                            return acc
                        }, {})

                        return {
                            timestamp: assessment.timestamp,
                            // Apply timezone offset to each timestamp
                            localTimestamp: this.convertPosixToLocalTime(
                                this.calculateTimezoneOffset(assessment.timestamp, assessment.timezone),
                                assessment.timestamp),
                            ...risks,
                        }
                    })
            },

            availableSeries () {
                return Object.values(constants.RISK_MODULES).reduce(function (acc, moduleName) {
                    acc[moduleName] = constants.MODULE_RISK_TYPES[moduleName].reduce(function(riskTypeAcc, riskType) {
                        riskTypeAcc[riskType] = []
                        return riskTypeAcc
                    }, {})
                    return acc
                }, {})
            },
        },

        watch: {
            options: function (options) {
                if (!this.chart && options) {
                    this.init()
                } else {
                    this.chart.dispose()
                    this.chart = null
                    this.init()
                }
            },

            toggleGraph: function () {
                this.chart.dispose()
                this.chart = null
                this.init()
                this.toggleEnabledModules()
                this.toggleEnabledRiskTypes()
            },

            disabledRiskTypes: function () {
                this.toggleEnabledRiskTypes()
            },

            selectedAssessmentTimestamp: function (selectedAssessmentTimestamp) {
                this.setDateMarker()
            },

            selectedModule: function (selectedModule) {
                // If the previous or currently selected module were the aggregate,
                // the chart needs to be refreshed due to differences in styling compared to other modules
                if (selectedModule === constants.RISK_MODULES.AGGREGATE || this.previousModule === constants.RISK_MODULES.AGGREGATE) {
                  this.destroyChart()
                  this.init()
                }
                this.toggleEnabledModules()
                this.previousModule = selectedModule
            },
        },

        mounted () {
            if (!this.chart) {
                this.init()
                this.toggleEnabledModules()
                this.toggleEnabledRiskTypes()
            }
        },

        updated() {
            this.init()
        },

        beforeDestroy() {
            if (this.chart) {
                this.chart.dispose()
                this.chart = null
            }
        },

        methods: {
            init () {
                if (!this.chart) {
                    Anychart.licenseKey(process.env.VUE_APP_ANYCHART_LICENSE)
                    // Timezone correction has already been performed before adding in the data, so we can set the offset to 0
                    // This is call ensures the initial page load displays the correct timestamps in the x-axis
                    Anychart.format.outputTimezone(0)
                    this.chart = Anychart.line()
                    this.createMapping()
                    this.createSeries()
                    this.addLicenseKey()
                    this.addGeneralSettings()
                    this.setDateMarker()
                    this.setListener()
                    this.chart.container(this.$el)
                    this.chart.draw()
                }
            },

            addLicenseKey () {
                let credits = this.chart.credits()
                credits.enabled(false)
            },

            createMapping () {
                let dataSet = Anychart.data.set(this.graphData)
                this.mappings = cloneDeep(this.availableSeries)
                for (let module of Object.keys(this.availableSeries)) {
                    for (let riskType of Object.keys(this.availableSeries[module])) {
                        this.mappings[module][riskType] = dataSet.mapAs({x: 'localTimestamp', value: `${riskType}`, marker: `${riskType}Marker`})
                    }
                }
            },

            createSeries () {
                this.series = cloneDeep(this.availableSeries)
                for (let module of Object.keys(this.mappings)) {
                    for (let riskType of Object.keys(this.mappings[module])) {
                        this.series[module][riskType] = this.chart.line(this.mappings[module][riskType])
                        let seriesEnabled = module === this.selectedModule
                        this.series[module][riskType].name(this.$t(`riskTypes.${riskType}`))
                        this.series[module][riskType].enabled(seriesEnabled)
                        this.series[module][riskType].stroke(getLine(module,riskType))
                        this.series[module][riskType].markers().type('circle')
                        if (riskType === constants.RISK_MODULES.AGGREGATE) {
                            this.series[module][riskType].markers().enabled(true)
                            this.series[module][riskType].markers().size(5)
                        }
                    }
                }
            },

            setListener () {
                let that = this
                this.chart.listen("click", function (e) {
                    that.handleClick(e)
                })
            },

            setGraphToggle(newInput) {
                this.toggleGraph = newInput
            },

            getIndexFromPoint (point) {
                let jointRiskColors = this.graphData.find(data => {
                    return data.localTimestamp == point
                })
                return jointRiskColors
            },

            handleClick (event) {
                let point = this.getXvalueFromEvent(event)
                let timestamp = (this.graphData.find(data => data.localTimestamp === point)).timestamp
                this.$store.dispatch('updateSelectedAssessmentTimestamp', timestamp)

                if (this.selectedModule === constants.RISK_MODULES.MOTION) {
                    let pointIndex = this.getIndexFromPoint(point)
                    let jointRiskColors = mapJointsToColors(pointIndex)
                    EventBus.$emit('UPDATE_CHARACTER_VIEWER', jointRiskColors)
                }
            },

            getXvalueFromEvent (event) {
                // x hover coordinate on plot
                const x = event.offsetX
                // plot bounds related to chart container
                const plotWidth = this.chart.getPixelBounds().width
                // plot left margin
                const plotLeftOffset = this.chart.getPixelBounds().left
                // is click on data area
                if (x < plotLeftOffset || x > plotLeftOffset + plotWidth) {
                    return
                }

                // get date of click related to xScale
                const ratio = (x - plotLeftOffset) / plotWidth
                return this.chart.xScale().inverseTransform(ratio)
            },

            addGeneralSettings () {
                this.setYAxisScale()
                const isAggregate = this.selectedModule === constants.RISK_MODULES.AGGREGATE
                generalStyling(this.chart, isAggregate)
                isAggregate ? this.chart.yAxis().title(this.$t(`individualSummary.totalRisks`).toUpperCase()) : this.chart.yAxis().title(this.$t(`individualSummary.riskSeverity`).toUpperCase())
            },

            toggleEnabledModules () {
                for (let module of Object.keys(this.availableSeries)) {
                    for (let riskType of Object.keys(this.availableSeries[module])) {
                        const moduleEnabled = module === this.selectedModule
                        const isEnabled = !this.disabledRiskTypes.includes(riskType) && moduleEnabled
                        this.series[module][riskType].enabled(isEnabled)
                    }
                }

                this.setYAxisScale()
            },

            toggleEnabledRiskTypes () {
                for (let module of Object.keys(this.availableSeries)) {
                    for (let riskType of Object.keys(this.availableSeries[module])) {
                        const moduleEnabled = module === this.selectedModule
                        const isEnabled = !this.disabledRiskTypes.includes(riskType) && moduleEnabled
                        this.series[module][riskType].enabled(isEnabled)
                    }
                }
            },

            setDateMarker () {
                if (this.graphData.length > 1) {
                    const xAxis = this.chart.xAxis()
                    const marker = this.chart.lineMarker(0)
                    const timestamp = this.convertPosixToLocalTime(
                        this.calculateTimezoneOffset(this.selectedAssessment.timestamp, this.selectedAssessment.timezone),
                        this.selectedAssessment.timestamp)
                    marker.axis(xAxis)
                    marker.value(timestamp)
                    marker.stroke({
                      color: constants.COLORS.WHITE,
                      thickness: generalSettings.chart.dateMarker.thickness/this.graphData.length,
                      opacity: generalSettings.chart.dateMarker.opacity,
                    })
                }
            },

            setYAxisScale () {
                if (this.selectedModule === constants.RISK_MODULES.AGGREGATE) {
                    this.chart.yScale(Anychart.scales.linear())
                    this.chart.yScale().minimum(0)
                } else {
                    this.toggleGraph ? this.linearGraphSettings() : this.logGraphSettings()

                    const orange = this.chart.lineMarker(1)
                    orange.axis(this.chart.yAxis())
                    orange.value(0.01)
                    orange.stroke(generalSettings.chart.grid.color)

                    const red = this.chart.lineMarker(2)
                    red.axis(this.chart.yAxis())
                    red.value(0.1)
                    red.stroke(generalSettings.chart.grid.color)
                }
            },

            extractRiskValues (riskTypeObject) {
                return Object.keys(riskTypeObject).reduce(function (acc, riskType) {
                    //y-axis workaround
                    //risk scores of 0 have been converted to 0.001 in order to be plotted
                    //will still appear green and display a risk score of 0.000 in the tooltip
                    if (riskTypeObject[riskType].risk >= 0.001) {
                      acc[`${riskType}Marker`] = setMarkerColor(riskTypeObject[riskType].risk)
                      acc[riskType] = riskTypeObject[riskType].risk
                    } else {
                      acc[`${riskType}Marker`] = setMarkerColor(riskTypeObject[riskType].risk)
                      acc[riskType] = 0.001
                    }
                    return acc
                }, {})
            },

            logGraphSettings() {
                this.chart.yScale(Anychart.scales.log())
                this.chart.yScale().minimum(0.001)
                this.chart.yScale().maximum(0.999)
                var ticksArray = [0.001, 0.0032, 0.01, 0.032, 0.1, 0.320, 0.999]
                this.chart.yScale().ticks().set(ticksArray)
                this.chart.yAxis().labels().format(replaceLabels)
            },

            linearGraphSettings() {
                this.chart.yScale(Anychart.scales.linear())
                this.chart.yScale().minimum(0.0009)
                this.chart.yScale().maximum(1)
                var ticksArray = [0.05, 0.1, 0.550]
                this.chart.yScale().ticks().set(ticksArray)
                this.chart.yAxis().labels().format(replaceLinearLabels)
            },

            destroyChart() {
              if (this.chart) {
                this.chart.dispose()
                this.chart = null
              }
            },
        },
    }
</script>

<style lang="scss">
@import '~design';
.ChartAssessmentsSummary {
    height: 100%;
    width: 100%;
    padding-top: 0.5rem;
    padding-right: 1.5rem;

    @include anychart-tooltip;
    .anychart-tooltip {
        width: 6.25rem;
        margin-left: 1rem;
    }

    &-toggle {
        padding-bottom: 0.2rem;
        padding-left: 3.6rem;
    }

}

</style>

<template lang="pug">
    div(class="sz-PanelRiskTypeSelector")
        div(
            v-if="moduleHasRiskTypeSubGroups",
            class="sz-PanelRiskTypeSelector-riskSections")
            div(
                v-for="group in moduleRiskTypeSubGroups.groups",
                :key="group",
                class="sz-PanelRiskTypeSelector-riskSection")
                div(class="sz-PanelRiskTypeSelector-riskSection-title") {{ $t(`riskAreas.motion.jointsShortened.${group}`) }}
                div(class="sz-PanelRiskTypeSelector-riskSection-buttons")
                    div(
                        v-for="(risk, index) in Object.keys(groupedModuleRiskTypes[group])",
                        :key="index",
                        @click="toggleRiskType(risk)",
                        :class="groupedModuleRiskTypes[group][risk]"
                        class="sz-PanelRiskTypeSelector-button")
                        div(
                            :class="`color-${groupedModuleRiskTypes[group][risk].color}`",
                            class="sz-PanelRiskTypeSelector-button-riskColor")
                        div(class="sz-PanelRiskTypeSelector-button-riskName") {{ $t(`riskTypesShortened.${risk}`) }}
                        div(
                            class="sz-PanelRiskTypeSelector-button-riskMarker",
                            :style="riskGraphMarkers(risk)",
                            :class="`sz-PanelRiskTypeSelector-button-riskMarker`")
        div(
            v-else-if="moduleTypeLength > 1",
            class="sz-PanelRiskTypeSelector-riskButtons")
            div(
                v-for="(risk, index) in Object.keys(moduleRiskTypes)",
                :key="index",
                @click.stop="toggleRiskType(risk)",
                :class="moduleRiskTypes[risk]"
                class="sz-PanelRiskTypeSelector-button")
                div(
                    :class="`color-${moduleRiskTypes[risk].color}`",
                    class="sz-PanelRiskTypeSelector-button-riskColor")
                div(class="sz-PanelRiskTypeSelector-button-riskName") {{ $t(`riskTypesShortened.${risk}`) }}
                div(
                    class="sz-PanelRiskTypeSelector-button-riskMarker",
                    :class="`sz-PanelRiskTypeSelector-button-riskMarker`")
                div(
                    class="sz-PanelRiskTypeSelector-button-riskMarker",
                    :style="riskGraphMarkers(risk)",
                    :class="`sz-PanelRiskTypeSelector-button-riskMarker`")
</template>

<script>
    import { mapGetters } from 'vuex'
    import constants from 'helpers/constants'
    import { cloneDeep } from 'lodash'
    import { getLine } from 'helpers/individualSummaryGraph'

    export default {
        computed: {
            ...mapGetters([
                'selectedModuleAssessmentDetail',
                'selectedModule',
                'disabledRiskTypes',
            ]),

            moduleRiskTypes () {
                let riskTypes =[]

                if (this.selectedModule === constants.RISK_MODULES.AGGREGATE || this.selectedModule === constants.ASSESSMENT_PAGE.EDITOR) return riskTypes

                if (this.selectedModuleAssessmentDetail.riskTypes) {
                    riskTypes = cloneDeep(this.selectedModuleAssessmentDetail.riskTypes)
                }
                Object.keys(riskTypes)
                    .forEach((riskType) => {
                        riskTypes[riskType] = {
                            ...riskTypes[riskType],
                            disabled: this.disabledRiskTypes.includes(riskType),
                        }
                    })

                return riskTypes
            },

            moduleHasRiskTypeSubGroups () {
                return constants.MODULES_WITH_RISK_TYPE_GROUPS.includes(this.selectedModule)
            },

            moduleRiskTypeSubGroups () {
                return this.moduleHasRiskTypeSubGroups
                    ? constants.RISK_MODULE_GROUPS[this.selectedModule]
                    : []
            },

            groupedModuleRiskTypes () {
                if (this.moduleHasRiskTypeSubGroups) {
                    let groupMap = {}
                    for (let group of Object.values(this.moduleRiskTypeSubGroups.groups)) {
                        const map = constants[this.moduleRiskTypeSubGroups.groupMap]
                        const risks = map[group]
                        let riskDetails = {}
                        for (let risk of risks) {
                            riskDetails[risk] = this.moduleRiskTypes[risk]
                        }
                        groupMap[group] = riskDetails
                    }
                    return groupMap
                }
                return false
            },

            moduleTypeLength () {
                return Object.keys(this.moduleRiskTypes).length
            },
        },

        methods: {
            toggleRiskType (riskType) {
                this.$store.dispatch('toggleRiskType', riskType)
            },

            riskGraphMarkers (risk) {
                let style = getLine(this.selectedModule, risk)
                let color = (style) ? style.color : 'grey'
                let line = (style) ? constants.COLORS.INDIV_SUMMARY.ERGO_LINES_MAP[style.dash] : 'solid'

                return {
                    borderTop: `0.125rem ${line}` ,
                    width: '50%',
                    color: color,

                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-PanelRiskTypeSelector {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 8.5rem;
        overflow-x: auto;
        @include scrollbar-widget;
        min-height: 8.5rem;

        &-riskSections {
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 98%;
        }

        &-riskButtons {
            display: flex;
            display: flex;
            flex-direction: row;
            height: 100%;
            align-items: center;
            justify-content: center;
        }

        &-button {
            cursor: pointer;
            margin: 0 0.25rem;
            width: 6.25rem;
            height: 5rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            background-color: $color-chart-background;
            @extend %font-topbar-heading;
            font-size: 12px;

            &.disabled {
                background-color: inherit;
            }

            &-riskColor {
                width: 0.75rem;
                height:0.75rem;
                border-radius: 0.25rem;

                &.color-0 {
                    background-color: $color-risk-green;
                }

                &.color-1 {
                    background-color: $color-risk-yellow;
                }

                &.color-2 {
                    background-color: $color-risk-orange;
                }

                &.color-3 {
                    background-color: $color-risk-red;
                }
            }
        }

        &-riskSection {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            &-title {
                @extend %font-topbar-heading;
                font-size: 14px;
            }

            &-buttons {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin: 0 0.25rem;
            }
        }
    }

</style>

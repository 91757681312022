<template lang="pug">
    Layout
        IndividualSummary(:workerId="workerId")
</template>

<script>
    import { mapGetters } from 'vuex'
    import Layout from '../layouts/main'
    import IndividualSummary from 'components/Dashboards/IndividualSummary'

    export default {
        name: "SummaryView",

        components: {
            Layout,
            IndividualSummary,
        },

        props: {
            workerId: {
                type: String,
                required: true,
            },
        },

        computed: {
            ...mapGetters([
                'userID',
                'userHasAssessorRole',
            ]),
        },

        mounted() {
            if (this.isViewRestrictedToUser()) {
                this.$router.push({ name: '404' })
            }
        },

        methods: {
            isViewRestrictedToUser() {
                return !this.userHasAssessorRole && this.workerId !== this.userID
            },
        },
    }
</script>

<style lang="scss" scoped>
</style>
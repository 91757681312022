<template lang="pug">
    div(
        @click="generateMotionAssessment",
        class="sz-RiskAreaAxisButton",
        :class="`sz-RiskAreaAxisButton-` + rRoyColour")
        div(class="sz-RiskAreaAxisButton-label") {{ $t(`riskAreas.motion.${name}`) }}
</template>

<script>
    import { mapGetters } from 'vuex'
    import constants from 'helpers/constants'
    import { decomposeRROY } from "helpers/graph"

    export default {
        name: "RiskAreaAxisButton",

        props: {
            name: {
                type: String,
                required: true,
            },
            score: {
                type: Number,
                required: true,
            },
        },

        computed: {
            ...mapGetters([
                'currentCapture',
                'workerId',
            ]),

            rRoyColour () {
                let rroy = decomposeRROY(this.score)

                if (!!rroy[0]) return constants.RISK_SCORES.RED
                if (!!rroy[1]) return constants.RISK_SCORES.ORANGE
                if (!!rroy[2]) return constants.RISK_SCORES.YELLOW
                return constants.RISK_SCORES.GREEN
            },
        },

        methods: {
            generateMotionAssessment () {
                this.$router.push({
                    name: constants.PAGES.ASSESSMENT.MODULE,
                    params: {
                        workerId: this.workerId,
                        captureIdx: this.currentCapture.capture,
                        assessmentIdx: this.currentCapture.assessment,
                        riskType: this.name,
                        assessmentDate: this.currentCapture.startTimePosix,
                    },
                })
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';
    .sz-RiskAreaAxisButton {
        height: 1.5rem;
        margin: 0.25rem;
        width: 9rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid white;
        color: black;
        cursor: pointer;

        font-size: 13px;

        &-label {
            padding: 0.5rem;
        }

        &-0 {
            background-color: $color-risk-green;
        }

        &-1 {
            background-color: $color-risk-yellow;
        }

        &-2 {
            background-color: $color-risk-orange;
        }

        &-3 {
            background-color: $color-risk-red;
        }
    }

</style>
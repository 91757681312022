import constants from 'helpers/constants'

export function getLine(module, risk) {
    if (!Object.keys(constants.INDIVIDUAL_SUMMARY_GRAPH_LINES_MAP).includes(module)) return
    if (riskLines[constants.INDIVIDUAL_SUMMARY_GRAPH_LINES_MAP[module]].hasOwnProperty(risk)) {
        return riskLines[constants.INDIVIDUAL_SUMMARY_GRAPH_LINES_MAP[module]][risk]
    }
    return
}

export const riskLines = {
    postureRiskLines: {
        leftShoulderPosture: {
            color: constants.COLORS.INDIV_SUMMARY.ERGO_COLORS.L_SHOULDER,
            thickness: 2,
            dash: 0,
        },

        rightShoulderPosture: {
            color: constants.COLORS.INDIV_SUMMARY.ERGO_COLORS.R_ELBOW,
            thickness: 2,
            dash: 0,
        },
    },

    vibrationRiskLines: {
        leftHandVibration: {
            color: constants.COLORS.INDIV_SUMMARY.ERGO_COLORS.L_SHOULDER,
            thickness: 2,
            dash: 0,
        },

        rightHandVibration: {
            color: constants.COLORS.INDIV_SUMMARY.ERGO_COLORS.R_ELBOW,
            thickness: 2,
            dash: 0,
        },
    },

    motionRiskLines: {
        leftShoulderRot: {
            color: constants.COLORS.INDIV_SUMMARY.ERGO_COLORS.L_SHOULDER,
            thickness: 2,
            dash: 0,
        },

        leftShoulderInc: {
            color: constants.COLORS.INDIV_SUMMARY.ERGO_COLORS.L_SHOULDER,
            thickness: 2,
            dash: 5,
        },

        rightShoulderRot: {
            color: constants.COLORS.INDIV_SUMMARY.ERGO_COLORS.R_SHOULDER,
            thickness: 2,
            dash: 0,
        },

        rightShoulderInc: {
            color: constants.COLORS.INDIV_SUMMARY.ERGO_COLORS.R_SHOULDER,
            thickness: 2,
            dash: 5,
        },

        leftElbowFlexExt: {
            color: constants.COLORS.INDIV_SUMMARY.ERGO_COLORS.L_ELBOW,
            thickness: 2,
            dash: 0,
        },

        rightElbowFlexExt: {
            color: constants.COLORS.INDIV_SUMMARY.ERGO_COLORS.R_ELBOW,
            thickness: 2,
            dash: 0,
        },

        backRot: {
            color: constants.COLORS.INDIV_SUMMARY.ERGO_COLORS.BACK,
            thickness: 2,
            dash: 0,
        },

        backFlexExt: {
            color: constants.COLORS.INDIV_SUMMARY.ERGO_COLORS.BACK,
            thickness: 2,
            dash: 2,
        },

        backSideBend: {
            color: constants.COLORS.INDIV_SUMMARY.ERGO_COLORS.BACK,
            thickness: 2,
            dash: 5,
        },

        defaultSolidLine: {
            thickness: 2,
            dash: 0,
        },
    },
}

<template lang="pug">
    div(class="sz-PanelModuleSelector")
        div(
            v-for="risk in riskNames",
            :class="['sz-PanelModuleSelector-risk', 'sz-PanelModuleSelector-risk-'+ risk, \
            {'sz-PanelModuleSelector-risk-inActive': !riskSummaries[risk].selectable }]",
            @click="updateSelectedModule(risk)")
            div(
                class="sz-PanelModuleSelector-risk-button",
                :class="[{'sz-PanelModuleSelector-risk-button-selected': risk === selectedModule}, \
                {'sz-PanelModuleSelector-risk-button-inActive': !riskSummaries[risk].selectable }]")
                div(:class="['sz-PanelModuleSelector-risk-button-label',\
                    'sz-PanelModuleSelector-risk-button-label-'+ risk]") {{ $t(`individualSummary.riskTypes.${risk}`) }}
                div(:class="[ 'sz-PanelModuleSelector-risk-value', `sz-PanelModuleSelector-value-${risk}`]") {{ riskSummaries[risk].value }}
</template>

<script>

    import constants from 'helpers/constants'
    import { mapGetters } from 'vuex'

    export default {

        computed: {
            ...mapGetters([
                'selectedAssessment',
                'selectedAssessmentTimestamp',
                'selectedModule',
            ]),

            riskSummaries () {
                let that = this
                return Object.values(constants.RISK_MODULES)
                    .reduce(function (acc, module) {
                        let riskValue = (that.selectedAssessment[module]) ? that.selectedAssessment[module].risk : undefined
                        acc[module] = {
                            value: riskValue || riskValue === 0 ? riskValue : that.$t(`individualSummary.notAvailable`),
                            selectable: riskValue || riskValue === 0 ? true : false,
                        }
                        return acc
                    }, {})
            },

            riskNames () {
                return Object.keys(this.riskSummaries)
            },
        },

        methods: {
            updateSelectedModule (risk) {
                if (this.riskSummaries[risk].selectable) {
                    this.$store.dispatch('updateSelectedModule', risk)
                }
            },
        },

    }
</script>

<style lang="scss" scoped>
    @import '~design';
    .sz-PanelModuleSelector {
        display: flex;
        flex-direction: row;
        width: 100%;
        min-height: 4.5rem;
        max-height: 4.5rem;
        background-color: $color-black;
        border-bottom: 1px solid $color-lifebooster-extra-light-green;

        &-risk {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 100%;
            justify-content: space-between;
            cursor: pointer;

            &-inActive {
                cursor: not-allowed;
            }

            &-button {
                display: flex;
                width: 100%;
                margin-top: 0.185rem;
                margin-bottom: 0.185rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                @extend %font-heading;
                color: lighten($color-black, 50%);

                &-inActive {
                    background-color: #383838;
                }

                &-selected {
                    background-color: $color-lifebooster-medium-green;
                    color: $color-white;
                }
            }
        }
    }
</style>

<template lang="pug">
    div(class="sz-PanelModuleSummary")
        div(class="sz-PanelModuleSummary-top")
            div(class="sz-PanelModuleSummary-date") {{ assessmentDate }}
            div(class="sz-PanelModuleSummary-assessmentID")
                CopyableText(
                  :text="assessmentID")
            div(class="sz-PanelModuleSummary-assessmentTags")
                div(class="sz-PanelModuleSummary-assessmentTags-title") {{ $t(`individualSummary.assessmentTags`) }}
                div(class="sz-PanelModuleSummary-assessmentTags-body")
                    div(
                        v-for="tag in assessmentTags",
                        class="sz-PanelModuleSummary-assessmentTags-body-tags") {{ tag }}
            div(class="sz-PanelModuleSummary-excluded") {{ excludedFromCharts }}
        div(class="sz-PanelModuleSummary-bottom")
            div(
                v-if="showButton",
                @click="generateAssessment",
                class="sz-PanelModuleSummary-button")
                div(class="sz-PanelModuleSummary-button-text") {{ assessmentButtonTitle }}
</template>

<script>

    import { mapGetters } from 'vuex'
    import constants from 'helpers/constants'
    import moment from 'moment-timezone'
    import { userCanAccessPage } from 'helpers/permissionsHelper'
    import CopyableText from 'components/Shared/CopyableText'

    export default {
        components: {
            CopyableText,
        },

        computed: {
            ...mapGetters([
                'selectedModule',
                'selectedAssessmentTimestamp',
                'selectedAssessment',
                'workerId',
                'auth0User',
                'assessmentTagMapper',
            ]),

            assessmentDate () {
                return moment.tz((this.selectedAssessmentTimestamp * constants.UNIX_MILLISECONDS), this.selectedAssessment.timezone).format("MMM D, YYYY")
            },

            assessmentButtonTitle () {
                if (this.selectedModule === constants.RISK_MODULES.AGGREGATE) {
                    return this.$t('individualSummary.assessmentButton.editor')
                }

                return this.$t(`individualSummary.assessmentButton.${this.selectedModule}`)

            },

            getSelectedAssessmentType () {
                if (this.isAggregate) return constants.ASSESSMENT_PAGE.EDITOR

                return this.selectedModule
            },

            getRiskType () {
                if (this.isAggregate) return constants.MODULE_RISK_TYPES[constants.RISK_MODULES.MOTION][0]

                return constants.MODULE_RISK_TYPES[this.selectedModule][0]
            },

            isAggregate () {
                return this.selectedModule === constants.RISK_MODULES.AGGREGATE
            },

            showButton () {
                if (!userCanAccessPage(this.auth0User, constants.PAGES.ASSESSMENT.EDITOR) && this.isAggregate) return false
                return true
            },

            assessmentID () {
                return `${constants.MODULE_ASSESSMENT_ID_MAP[this.selectedModule]}-` + this.selectedAssessment.assessmentIdx
            },

            assessmentTags () {
                return this.selectedAssessment.assessmentTags.map(tagID => this.assessmentTagMapper[tagID])
            },

            excludedFromCharts () {
                return this.selectedAssessment.aggregationAllowed === false ? this.$t(`individualSummary.excludedFromCharts`) : ''
            },

            toggleOptions () {
                return ['', this.$t(`enhanceRedSeverityLabels.toggleGraph`)]
            },
        },

        methods: {
            generateAssessment () {
                let routeName = (this.selectedModule === constants.RISK_MODULES.AGGREGATE)
                    ? constants.PAGES.ASSESSMENT.EDITOR
                    : constants.PAGES.ASSESSMENT.MODULE

                this.$router.push({
                    name: routeName,
                    params: {
                        workerId: this.workerId,
                        captureIdx: this.selectedAssessment.captureIdx,
                        assessmentIdx: this.selectedAssessment.assessmentIdx,
                        riskType: this.getRiskType,
                        assessmentDate: this.selectedAssessmentTimestamp,
                        assessmentType: this.getSelectedAssessmentType,
                    },
                })
                if (this.isAggregate) this.$store.dispatch('updateSelectedModule', constants.ASSESSMENT_PAGE.EDITOR)
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-PanelModuleSummary {
        padding-top: 1rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        &-top {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: space-between;
            padding-bottom: 0.5rem;
        }

        &-date {
            @extend %font-topbar-heading;
            align-self: flex-start;
            padding-bottom: 0.25rem;
        }

        &-assessmentID {
            align-self: flex-start;
            width: 100%;
            padding-bottom: 0.25rem;
        }

        &-enhanceRedSeverity {
            padding-bottom: 0.50rem;
        }

        &-assessmentTags {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            padding-bottom: 0.25rem;

            &-title {
                @extend %font-topbar-heading;
                padding-bottom: 0.25rem;
            }

            &-body {
                @include scrollbar-widget;
                overflow-y: scroll;
                display: flex;
                align-self: flex-start;
                flex-wrap: wrap;
                width: 100%;
                max-height: 4.4rem;

                &-tags {
                    background-color: $color-tooltip-dark;
                    height: 1.15rem;
                    margin: 0.25rem;
                    padding: 0.35rem;
                    border-radius: 5px;
                    font-size: 14px;
                }
            }
        }

        &-excluded {
            @extend %font-topbar-heading;
            align-self: flex-start;
        }

        &-bottom {
            width: 100%;
        }

        &-button {
            cursor: pointer;
            background-color: $color-lifebooster-light-green;
            height: 3rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &:hover {
                background-color: $color-button-hover;
            }

            &-text {
                @extend %font-topbar-heading;
                font-size: 16px;
            }
        }
    }
</style>

<template lang="pug">
    transition(name="fade")
        div(
            v-if="showCaptureUnavailable",
            class="sz-IndividualSummary-empty") {{ $t('individualSummary.captureError', { workerName }) }}
        div(
            v-if="showWelcomeMessage",
            class="sz-IndividualSummary-welcome")
            div(class="sz-IndividualSummary-welcome-body")
                img(
                    v-bind:src="logo",
                    class="sz-IndividualSummary-welcome-body-image")
                div(class="sz-IndividualSummary-welcome-body-title") {{ $t(`individualSummary.welcomeMessage.title`) }}
                div(class="sz-IndividualSummary-welcome-body-message") {{ $t(`individualSummary.welcomeMessage.message1`) }}
                div(class="sz-IndividualSummary-welcome-body-message") {{ $t(`individualSummary.welcomeMessage.message2`) }}
        div(
            v-if="!loading",
            class="sz-IndividualSummary-body")
            PanelModuleSelector(v-if="hasAccessToAssessments")
            div(class="sz-IndividualSummary-body-middle")
                div(class="sz-IndividualSummary-body-middle-charts")
                    PostureDistributionChart(v-if="isPosture")
                    ChartAssessmentsSummary(
                                            v-if="hasAccessToAssessments")
                div(class="sz-IndividualSummary-body-middle-right")
                    PanelModuleSummary(
                                       v-if="hasAccessToAssessments")
                    //-module specific things go here
                    CaptureSummaryPanel(v-if="hasAccessToAssessments")
            PanelRiskTypeSelector(v-if="hasAccessToAssessments")
        div(
            v-if="loading",
            class="sz-IndividualSummary-loading")
            LoadingSpinner(color="light")
</template>

<script>
    import { mapGetters } from 'vuex'
    import constants from 'helpers/constants'
    import PanelModuleSelector from '../IndividualSummary/PanelModuleSelector'
    import PanelModuleSummary from '../IndividualSummary/PanelModuleSummary'
    import LoadingSpinner from 'components/Shared/LoadingSpinner'
    import PanelRiskTypeSelector from 'components/IndividualSummary/PanelRiskTypeSelector'
    import ChartAssessmentsSummary from 'components/IndividualSummary/ChartAssessmentsSummary'
    import CaptureSummaryPanel from 'components/IndividualSummary/CaptureSummaryPanel'
    import PostureDistributionChart from 'components/IndividualSummary/Posture/PostureDistributionChart'
    import { userHasRole } from 'helpers/permissionsHelper'

    export default {
        components: {
            PanelModuleSelector,
            PanelModuleSummary,
            PanelRiskTypeSelector,
            ChartAssessmentsSummary,
            LoadingSpinner,
            CaptureSummaryPanel,
            PostureDistributionChart,
        },

        props: {
            workerId: {
                type: String,
                required: true,
            },
        },

        data () {
            return {
                loading: true,
                logo: require("../../assets/images/LifeBoosterDarkLogo.png"),
            }
        },

        computed: {
            ...mapGetters([
                'selectedAssessment',
                'workerHasAnySkus',
                'selectedModule',
                'workerCard',
                'auth0User',
            ]),

            workerHasAnyCaptures () {
                return !(!this.loading && !this.selectedAssessment)
            },

            hasAccessToAssessments () {
                return this.workerHasAnyCaptures && this.workerHasAnySkus
            },

            workerName () {
                return  (!this.workerCard.firstName && !this.workerCard.lastName)
                    ? this.$t('homePage.worker')
                    : `${this.workerCard.firstName || ''}  ${this.workerCard.lastName || ''}`
            },

            isPosture () {
                return this.selectedModule === constants.RISK_MODULES.POSTURE
            },

            isViewingSelf () {
                if (this.workerCard.userID) {
                  return this.workerCard.userID === this.auth0User[constants.AUTH0_USER_INFO_KEYS.USER_ID]
                } else if (userHasRole(this.auth0User, constants.ROLES.WORKER)) {
                  return true
                } else {
                  return false
                }
            },

            showWelcomeMessage () {
                return (!this.hasAccessToAssessments && this.isViewingSelf) ? true : false
            },

            showCaptureUnavailable () {
                return (!this.hasAccessToAssessments && !this.isViewingSelf) ? true : false
            },
        },

        mounted () {
            this.loadNewWorker()

            if (this.selectedModule === constants.ASSESSMENT_PAGE.EDITOR) {
                this.$store.dispatch('updateSelectedModule', constants.ASSESSMENT_PAGE.AGGREGATE)
            }

            if (!this.allAssessmentTags || this.allAssessmentTags.length === 0) {
                this.$store.dispatch('getAllAssessmentTags')
            }
        },

        methods: {
            async loadNewWorker () {
                this.loading = true
                await this.$store.dispatch('updateWorkerId', this.workerId)
                await this.$store.dispatch('updateAssessments')
                await this.$store.dispatch('updateWorkerCard', { workerId: this.workerId })
                this.loading = false
            },


        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-IndividualSummary {
        &-welcome {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 25vh;

            &-body {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                &-image {
                    max-width: 5rem;
                    padding-bottom: 0.5rem;
                }

                &-title {
                    @extend %font-content;
                    color: $color-white;
                    padding-top: 0.5rem;
                    padding-bottom: 1.5rem;
                    font-size: 32px;
                    font-weight: 1000;
                }

                &-message {
                    @extend %font-content;
                    color: $color-white;
                    font-size: 25px;
                    font-weight: normal;
                }
            }
        }

        &-body {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            flex-grow: 2;
            overflow: visible;
            justify-content: flex-start;

            &-middle {
                display: flex;
                flex-direction: row;
                height: 100%;

                &-charts {
                    display: flex;
                    flex-direction: column;
                    min-height: 26rem;
                    width: 100%;
                }

                &-right {
                    width: 20.5rem;
                    max-width: 21%;
                    min-width: 10rem;
                }
            }
        }

        &-loading {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            top: 34px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            opacity: 0.5;
            filter: blur(1px);
        }

        &-overview {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: 25%;
        }

        &-empty {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
        }
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }

    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }

</style>

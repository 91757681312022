<template lang="pug">
    div(
        class="sz-RiskArea",
        :class="[`sz-RiskArea-` + joint]")
        div(class="sz-RiskArea-label") {{ $t(`riskAreas.motion.joints.` + joint) }}
        RiskAreaAxisButton(
            v-for="axis of axisNames",
            :key="axis"
            :score="axes[axis]",
            :name="axis")
</template>

<script>
    import RiskAreaAxisButton from './RiskAreaAxisButton'

    export default {
        name: "RiskArea",

        components: {
            RiskAreaAxisButton,
        },

        props: {
            joint: {
                type: String,
                required: true,
            },
            axes: {
                type: Object,
                required: true,
            },
        },

        computed: {
            axisNames () {
                return Object.keys(this.axes)
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~design';

    .sz-RiskArea {
        @extend %box-layout;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 10rem;

    }

</style>
